import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v("Request Pumping Quote "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"mt-n2"},[_c('div',[_vm._v(" Peeps' helpers are doing some research to provide you with contact information to request pumping quotes from local companies. Please check back soon. If you would like to be notified when that is done, just let Peeps know by clicking on this button "),_c('div',{staticClass:"mt-3 text-center"},[_c('let-peeps-know',{attrs:{"target":"_blank","depressed":"","color":"primary","subject":"the local pumping information is available","body":"Please let me know when the information about local companies that will give me a quote on my septic pumping is available.   Thank you.","icon":"mdi-email"}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }